import $ from 'jquery';
import prestashop from 'prestashop';

/**
 * Update address form on country change
 * Emit "addressFormUpdated" event
 *
 * @param selectors
 */
 function updateVille(villeDefault) {
  $.ajax({
    type: "POST",
    url: baseDir + "modules/varnish/loadajax.php",
    async: true,
    dataType: "json",
    data: {
      action: "getVilleV2",
      postcode: $('input[name=postcode]').val(),
      code_pays: $('select[name=id_country]').val()
    },
    success: function (data) {
      var toreturn = 0;
      if (data.result != false) {
        if (data.result.length > 1) {
          var ville = '<label class="col-12 col-md-3 form-control-label required">'+data.trad.city+'</label><div class="col-12 col-md-6"><span class="niceselect"><select name="city" id="city" class="form-control">';
          var myArray = data.result;
          ville += '<option data-country="'+this.id_country+'" value="' + this.ville + '">'+data.trad.choose+'</option>'
          $.each(myArray, function () {
            ville = ville + '<option data-country="'+this.id_country+'" value="' + this.ville + '">' + this.ville + '</option>';
          });
          ville = ville + '</select></span><span id="manuel" style="color:#b3d050;text-decoration:underline;cursor:pointer;display: block;font-size: 14px;">'+data.trad.clickhere+'</span></div><div class="col-12 col-md-3 form-control-comment"></div>';

          //$('#ville').html(ville);
          $('.city').html(ville);
        } else {
          $('.city').html('<label class="col-12 col-md-3 form-control-label required">Ville</label><div class="col-12 col-md-6"><input class="" name="city" type="text" value="'+data.result[0].ville+'" maxlength="64" required=""></div><div class="col-12 col-md-3 form-control-comment"></div>');
          if($('select[name="id_country"]').val() == "default" || $('select[name="id_country"]').val() == "" || $('select[name="id_country"]').val() == null ) {
            $('select[name=id_country]').val(data.result[0].id_country)
            var input = document.querySelector('#_phone');
            var iti = window.intlTelInputGlobals.getInstance(input);
            iti.setCountry(data.result[0].iso_code);
            toreturn = data.result[0].iso_code;
          }
        }

      } else {
        if(villeDefault)
          $('.city').html('<label class="col-12 col-md-3 form-control-label required">'+data.trad.city+'</label><div class="col-12 col-md-6"><input class="" name="city" type="text" value="'+villeDefault+'" maxlength="64" required=""></div><div class="col-12 col-md-3 form-control-comment"></div>');
        else 
          $('.city').html('<label class="col-12 col-md-3 form-control-label required">'+data.trad.city+'</label><div class="col-12 col-md-6"><input class="" name="city" type="text" value="" maxlength="64" required=""></div><div class="col-12 col-md-3 form-control-comment"></div>');
      }
      return toreturn;
    },
    error: function(data) {
      if(villeDefault)
        $('.city').html('<label class="col-12 col-md-3 form-control-label required">'+data.trad.city+'</label><div class="col-12 col-md-6"><input class="" name="city" type="text" value="'+villeDefault+'" maxlength="64" required=""></div><div class="col-12 col-md-3 form-control-comment"></div>');
      else 
        $('.city').html('<label class="col-12 col-md-3 form-control-label required">'+data.trad.city+'</label><div class="col-12 col-md-6"><input class="" name="city" type="text" value="" maxlength="64" required=""></div><div class="col-12 col-md-3 form-control-comment"></div>');
    }
  });
}
function handleCountryChange (selectors) {
  $('body').on('change', selectors.country, () => {
    var requestData = {
      id_country: $(selectors.country).val(),
      id_address: $(selectors.address + ' form').data('id-address'),
    };
    var getFormViewUrl = $(selectors.address + ' form').data('refresh-url');
    var formFieldsSelector = selectors.address + ' input';
    var ville = $('input[name="city"]').val()
    var country = $(selectors.country).val();
    var iso_code = $(selectors.country).find(':selected').attr('data-isocode');
    $.post(getFormViewUrl, requestData).then(function (resp) {
      var inputs = [];

      // Store fields values before updating form
      $(formFieldsSelector).each(function () {
        inputs[$(this).prop('name')] = $(this).val();
      });
      
      $(selectors.address).replaceWith(resp.address_form);
      // Restore fields values
      $(formFieldsSelector).each(function () {
        $(this).val(inputs[$(this).prop('name')]);
      });
      $(selectors.country).val(country)
      updateVille(ville);
      var input = document.querySelector("#_phone");
      window.intlTelInput(input, {
          // autoPlaceholder : "aggressive",
          onlyCountries: $('#_phonelistisocode').val().split(';'),
          initialCountry : iso_code,
          /*utilsScript: baseDir+'js/intl-tel-input-17.0.18/intl-tel-input-17.0.18/build/js/utils.js'*/
      });
      //$('select[name=indicatifTel]>option[data-country='+country+']').prop("selected",true);
      // $(ville).html(selectVille);
      prestashop.emit('updatedAddressForm', {target: $(selectors.address), resp: resp});
    }).fail((resp) => {
      prestashop.emit('handleError', {eventType: 'updateAddressForm', resp: resp});
    });
  });
}

$(document).ready(() => {
  handleCountryChange({
    'country': '.js-country',
    'address': '.js-address-form'
  });
});
